@import-normalize;

@import url('https://fonts.googleapis.com/css?family=Poppins:100,400,500,700&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,400,500,700&subset=latin,latin-ext');

html {
  font-size: 10px;
  height: 100%;
}

* {
  outline: none;
}

body {
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2;
  height: 100%;
}
#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
}

button {
  border: none;
}

/* Animation */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
